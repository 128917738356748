.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}
.container {
  display: flex;
  align-items: center;
  background-color: rgba(10, 10, 10, 0.6);
  flex-direction: column;
  z-index: 20;
  height: 100vh;
  width: 100%;
  max-height: 100%;
  position: absolute;
  z-index: 10;
  min-height: 1100px;
  max-width: 490px;
}

.backgroundContainer {
  width: 90%;
  height: 40%;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 22%;
  align-items: center;
  position: fixed;
}

.text {
  font-size: 20px;
  font-weight: 800;
  color: #00A656;
  margin-top: 70px;
  text-align: center;
}

.check {
  width: 85px;
  margin-top: 40px;
  height: 85px;
}
