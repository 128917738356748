
html {
  display: flex;
  justify-content: center;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
}
body {
  background: white;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 100vw;
  max-width: 490px;
  margin: 0;
  padding: 0;
  background-color: white;
  font-family: 'Patrick Hand', sans-serif
}
@media (max-width: 490px) {
   body {
    box-shadow:none;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


.react-tel-input .form-control:focus {
  border-color: #008648;
  box-shadow: 0px 0px 0px 1px #008648;
}


textarea:focus {
  outline-color: #37afff;
  outline-width: 0.1;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: none;
}

input[type='radio']:checked {
  accent-color: #008648;
}

input[type="radio"] {
  background-clip: content-box;
  border: 2px solid #bbbbbb;
  background-color: #e7e6e7;
  border-radius: 50%;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

/* input[type='number'] {
  -moz-appearance: textfield;
} */

@media (max-width: 490px) {
  body {
    height: 100vh;
    width: 100vw;
    /* padding-bottom: 120px; */
  }
}

.alice-carousel__prev-btn {
  display: none;
}

.css-eg0mwd-MuiSlider-thumb {
width: 30px !important;
height: 30px !important;
}

.cluster div {
  color: white!important;
  font-weight: bold!important;
  font-size: 14px!important;
}

.cluster img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fade-in-out {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in-out.splide__slide--is-active {
  opacity: 1;
}